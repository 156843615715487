export function toTwoDimensionalArray<T>(array: T[], columns: number) {
  return array.reduce((accumulator, current, index) => {
    const colIndex = Math.floor(index / columns)
    if (accumulator[colIndex] === undefined) {
      accumulator.push([])
    }
    accumulator[colIndex].push(current)
    return accumulator
  }, [] as T[][])
}
